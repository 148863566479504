import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoMdSearch } from "react-icons/io";
import Modal from "react-modal";
import Plan from "./Plan"; 
import { Navigate, useNavigate } from "react-router-dom";

// Import region images
import africa from '../assets/images/Buydatamap/africa.svg';
import asia from '../assets/images/Buydatamap/asia.svg';
import caribbean from '../assets/images/Buydatamap/caribbean.svg';
import europe from '../assets/images/Buydatamap/europe.svg';
import global from '../assets/images/Buydatamap/global.svg';
import MiddleEast from '../assets/images/Buydatamap/middleeast.svg';
import NorthAmerica from '../assets/images/Buydatamap/northamerica.svg';
import SouthLationAmerica from '../assets/images/Buydatamap/southlatingamerica.svg';
import Local from '../assets/images/local.svg';
import Regional from '../assets/images/Regional.svg'
import { GoDownload } from "react-icons/go";

const BuyData = () => {
  const [regionss, setregionss] = useState([]);
  const [selectedRegions, setselectedRegions] = useState(null);
  const [plans, setPlans] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selected,setSelected]=useState();
  const [userRole, setUserRole] = useState(null); // State for user role
  const [error, setError] = useState(null);

const navigate= useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    // Fetch user role from localStorage
    const role = localStorage.getItem("role");
    setUserRole(role);
  }, []);
  useEffect(() => {
    const fetchregionss = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${apiUrl}ESIM/Get_plancount_regionwise?flag=1`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        if (response.data.isSuccess) {
          setregionss(response.data.planlists);

          const europeRegion = response.data.planlists.find(region => region.regionname === 'Europe');
          if (europeRegion) {
            setselectedRegions(europeRegion.regionname);
            fetchPlans(europeRegion.region_id);
            setMenuOpen(true);
          }
        }
      } catch (error) {
        console.error('Error fetching regionss:', error);
      }
    };

    fetchregionss();
  }, [apiUrl]);

  const fetchPlans = async (regionId) => {
    try {
      const token = localStorage.getItem('token');
      const partnerCode = localStorage.getItem('partnerCode');
      const response = await axios.post(`${apiUrl}ESIM/Get_plan_byregion`, {
        region_id: regionId,
        country: 'string',
        partnerCode: partnerCode
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      if (response.data.isSuccess) {
        setPlans(response.data.getofflineplan);
      }
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };

  const handleRegionClick = async (regionId, regionName) => {
    setselectedRegions(regionName);
    fetchPlans(regionId);
  };

  const handleBuyClick = (plan) => {
    console.log('Selected Plan:', plan); // Debugging line
    localStorage.setItem("planCode", plan.plancode);
    localStorage.setItem("plan_id", plan.planid);
console.log(plan.plan_id,'plalsas')
    setSelectedPlan(plan);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const filteredRegions = regionss.filter(region =>
    region.regionname.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleNavigation = (storeType) => {
    setSelected(storeType);

    if (storeType === "local") {
      navigate("/BuyDataBundles"); // Navigate to Home
    } else if (storeType === "regional") {
      navigate("/Buydatad"); // Navigate to Regional eSIM
    }else{
      navigate("/BuyDataGlobal")
    }
  };

  const downloadCSV = async () => {
    try {
      const token = localStorage.getItem("token");
      const partnerCode = localStorage.getItem("partnerCode");
      if (!token) {
        setError("No token found. Please log in.");
        return;
      }
      const response = await fetch(`${apiUrl}ESIM/Get_all_plans?flag=1&PartnerCode=${partnerCode}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });
  
      const data = await response.json();
  
      if (data.isSuccess) {
        let { getallplan } = data;
  
        // Sort the plans alphabetically by country
        getallplan = getallplan.sort((a, b) => a.country.localeCompare(b.country));
  
        // Prepare CSV data
        const header = 'Country,Data,Validity,SellingCost\n';
        const rows = getallplan.map(plan => (
          `${plan.country},${plan.data === "" ? "Unlimited" : plan.data},${plan.validity},${plan.sellingcost}`
        )).join('\n');
  
        const csvContent = header + rows;
  
        // Create a blob and trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'plans.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };
  return (
    <div className="p-4">
    

      <div className="flex">
      <button
        onClick={() => handleNavigation("local")}
        className={`self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)] rounded-lg h-10 flex flex-row items-center justify-center p-2.5 box-border m-4 `}
      >
        <img src={Regional}
         className="h-6 w-6  filter invert sepia saturate-500 hue-rotate-180 brightness-150 contrast-100"
         alt="Regional Icon"
       
        />
       <h1>Local eSIM store</h1>
      </button>

      <button
        onClick={() => handleNavigation("regional")}
        className={`self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)] rounded-lg h-10 flex flex-row items-center justify-center p-2.5 box-border m-4 ${
          selected === "regional"
            ? "bg-brand-white text-brand-green-selected"
            : "bg-brand-green-selected text-brand-white"
        }`}
      >
        <img src={Local}
          className={`mr-2 ${
            selected === "regional" ? "text-brand-green-selected" : "text-brand-white"
          }`}
        />
        Regional eSIM store
      </button>
        

      <button
        onClick={() => handleNavigation("global")}
        className={`self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)] rounded-lg h-10 flex flex-row items-center justify-center p-2.5 box-border m-4 `}
      >
        <img src={Regional}
         className="h-6 w-6  filter invert sepia saturate-500 hue-rotate-180 brightness-150 contrast-100"
         alt="Regional Icon"
       
        />
       <h1>Global eSIM store</h1>
      </button>

    </div>



      
      <div className="flex">
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="bg-[#117575] text-white rounded-lg shadow-md p-4 cursor-pointer flex items-center justify-center mb-4"
        >
          Regional Plans
        </button>

        {menuOpen && (
          <div style={{ backgroundColor: '#f8fafd', borderRadius: '2rem', maxHeight: '400px', overflowY: 'auto' }} className="flex-1 m-2 p-4">
            <div className="relative">
              <input
                type='search'
                placeholder='Search Country'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className='w-full self-stretch rounded-3xl bg-brand-gray h-10 flex flex-row items-center justify-start py-2 px-3 box-border gap-[12px] mr-2 mt-4 pl-10'
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <IoMdSearch size={20} className="text-gray-500" />
              </div>
            </div>
            <h2 className="text-xl font-semibold mt-8">Available Regions</h2>

            <ul>
              {filteredRegions.map((region) => (
                <li
                  key={region.region_id}
                  onClick={() => handleRegionClick(region.region_id, region.regionname)}
                >
                  <div
                    className={`self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)] rounded-xl ${selectedRegions === region.regionname ? 'bg-brand-green-selected text-brand-white' : 'bg-brand-gray text-black'} border-blue-500 h-10 flex flex-row items-center justify-center p-2.5 box-border m-4`}
                  >
                    <button>
                      <div className="relative font-medium">{region.regionname }</div>
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

<div style={{ backgroundColor: '#f8fafd', borderRadius: '2rem' }} className="flex-1 mr-2 p-4 w-96 text-5xl font-bold text-[#197979] flex flex-col items-center justify-center">
          {selectedRegions === 'Africa' && <><h1 className="text-center">Africa</h1><img src={africa} alt="africa" className="mx-auto" /></>}
          {selectedRegions === 'Asia-Pacific' && <><h1 className="text-center">Asia</h1><img src={asia} alt="asia" className="mx-auto" /></>}
          {selectedRegions === 'Eurasia' && <><h1 className="text-center">Eurasia</h1><img src={caribbean} alt="Eurasia" className="mx-auto" /></>}

          {selectedRegions === 'Caribbean' && <><h1 className="text-center">Caribbean</h1><img src={caribbean} alt="caribbean" className="mx-auto" /></>}
          {selectedRegions === 'Europe' && <><h1 className="text-center">Europe</h1><img src={europe} alt="europe" className="mx-auto" /></>}
          {selectedRegions === 'Global' && <><h1 className="text-center">Global</h1><img src={global} alt="global" className="mx-auto" /></>}
          {selectedRegions === 'Middle-east' && <><h1 className="text-center">Middle East</h1><img src={MiddleEast} alt="middleeast" className="mx-auto" /></>}
          {selectedRegions === 'North-America' && <><h1 className="text-center">North America</h1><img src={NorthAmerica} alt="northamerica" className="mx-auto" /></>}
          {selectedRegions === 'South-Latin-America' && <><h1 className="text-center">South Latin America</h1><img src={SouthLationAmerica} alt="southlatinamerica" className="mx-auto" /></>}
          {!selectedRegions && <><h1 className="text-center">Regional</h1><img src={global} alt="global" className="mx-auto" /></>}
        </div>
      </div>

  
      <div style={{ backgroundColor: '#f8fafd' }} className="text-black rounded-md bg-e9f8f9 p-4 mt-4">
        
        
      <div className="flex items-center justify-between mb-4">
          <h1 className="text-xl font-bold"> All Packages</h1>

          <button
      onClick={downloadCSV}
      className="px-4 py-2 text-[#105255] rounded-md font-bold flex items-center space-x-2"
    >
      <GoDownload size={28} />
      <span>Export</span>
    </button>   




         </div>{selectedRegions && plans.length > 0 && (
          <table className="w-full">
            <thead className='py-2 px-4 mb-4'>
              <tr className="border-b">
              <th className="text-center p-3 px-5">Country/Region</th>
                <th className="text-center p-3 px-5">Data</th>
                <th className="text-center p-3 px-5">Validity (Days)</th>
                {/* <th className="text-center p-3 px-5">Plan Name</th> */}
                <th className="text-center p-3 px-5">Plan Code</th>

                 {userRole === 'Partner' && (
                    <>
                      <th className="text-center p-3 px-5">Discount (%)</th>
                      <th className="text-center p-3 px-5">Selling Cost</th>
                    </>
                  )}
                <th className="text-center p-3 px-5">Buy</th>
              </tr>
            </thead>
            <tbody className="border-b hover:bg-gray-100">
              {plans.map((plan) => (
                <tr key={plan.planid} className="border-b hover:bg-gray-100">
<td className="text-center p-3 px-5">{plan.geography}</td>
                 <td className="text-center p-3 px-5">
                 {plan.data !== "" ? plan.data : "Unlimited"}
                 </td>
                 <td className="text-center p-3 px-5">{plan.vaildity}</td>
                 {/* <td className="text-center p-3 px-5">{plan.planName}</td> */}
                  <td className="text-center p-3 px-5">{plan.plancode}</td>
                  {userRole === 'Partner' && (
                    <>
                      <td className="text-center p-3 px-5">{plan.markuDiscount * 100}%</td>
                      <td className="text-center p-3 px-5">${plan.sellingcost}</td>
                    </>  )}
                 <td className="text-center ">
                    <div className="self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)] rounded-xl bg-brand-green-selected  border-blue-500 h-10 flex flex-row items-center justify-center p-2.5 box-border text-brand-white m-4">
                      <button onClick={() => handleBuyClick(plan)}>Buy</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "80%",
            margin: "auto",
            padding: "0px",
            borderRadius: "8px",
            height: "fit-content",
          },
        }}
      >
        {selectedPlan ? <Plan plan={selectedPlan} closeModal={closeModal} /> : <p>Loading...</p>}
      </Modal>
    </div>
  );
};

export default BuyData;
