import Layout from './components/Layout'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Login from './pages/Login'
import CreateAccount from './pages/CreateAccount'
import BuyDataBundles from './pages/BuyDataBundles'
import SimMangement from './pages/SimMangement'
import Plan from './pages/Plan'
import Signup from './pages/Signup'
import TopUps from './pages/TopUps'
import Step5 from './pages/Step5'
import Step4 from './pages/Step4'
import Step1 from './pages/Step1'
import Step2 from './pages/Step2'
import Step3 from './pages/Step3'
import GetPlanInformation from './pages/GetPlanInformation'
import Yourticket from './pages/Yourticket'
import CreateTicket from './pages/CreateTicket'
import Superadmin from './pages/Superadmin'
import SuperadminWallet from './pages/SuperadminWallet'
import HelpSupport from './pages/HelpSupport'
import StepHeader from './pages/StepHeader'
import SimManagement from './pages/SimManagement'
import Apibox from './pages/Apibox'
import News from './pages/News'
import SuperadminDashboard from './pages/SuperadminDashboard'
import UserProfile from './pages/UserProfile'
import Forgotpassword from './pages/Forgotpassword'
import AddmoreBalance from './pages/AddmoreBalance'
import SuperadminTicket from './pages/SuperadminTicket'
import SaleType from './pages/SaleType'
import SuperadminPartner from './pages/SuperadminPartner'
import Buydatad from './pages/Buydatad'
import SuperadminVendor from './pages/SuperadminVendor'
import Superadminpay from './pages/Superadminpay'
import SuperadminUpdate from './pages/SuperadminUpdate'
import TermsOfUse from './pages/Terms&Use'
import PrivacyPolicy from './pages/Terms&Privacy'
import BuyDataGlobal from './pages/BuyDataGlobal'


function App() {  
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path='/' element={<Login />} />
                    <Route path='/Signup' element={<Signup />} />      
                    <Route path ='Forgotpassword' element={<Forgotpassword />}/>

                    {/* SIDEBAR PARTNER ROUTES */}

                    <Route path='/Home' element={<Home />} />
                    <Route path='/BuyDataBundles' element={<BuyDataBundles />} />
                    <Route path ='GetPlanInformation' element={<GetPlanInformation />}/>
                    <Route path ='AddmoreBalance' element={<AddmoreBalance />}/>
                    <Route path='/News' element={<News />} />
                    <Route path='/SimMangement' element={<SimMangement />} />
                    <Route path='/Apibox' element={<Apibox />} />
                    <Route path ='/HelpSupport' element={<HelpSupport />}/>
                    <Route path='/CreateTicket' element={<CreateTicket />} />      

                    {/* OTHER PARTNER ROUTES ARE */}

                    <Route path='/Plan' element={<Plan />} />
                    <Route path='/CreateAccount' element={<CreateAccount />} />
                    <Route path ='/UserProfile' element={<UserProfile />}/>
                    <Route path ='SaleType' element={<SaleType />}/>
                    <Route path='/Yourticket' element={<Yourticket />} />      
                    <Route path='/BuyDataGlobal' element={<BuyDataGlobal />} />      

                    {/* ALL STEPS ROUTES */}

                    <Route path='/Step1' element={<Step1 />} />
                    <Route path='/Step2' element={<Step2 />} />
                    <Route path='/Step3' element={<Step3 />} />
                    <Route path='/Step4' element={<Step4 />} />      
                    <Route path='/Step5' element={<Step5 />} />  
                    <Route path ='/StepHeader' element={<StepHeader />}/>


                    {/* superadmin access */}
                    <Route path ='/SuperadminDashboard' element={<SuperadminDashboard />}/>
                    <Route path ='/Superadmin' element={<Superadmin />}/>
                    <Route path ='/SuperadminWallet' element={<SuperadminWallet />}/>
                    <Route path ='SuperadminTicket' element={<SuperadminTicket />}/>
                    <Route path ='SuperadminPartner' element={<SuperadminPartner />}/>
                    <Route path ='SuperadminVendor' element={<SuperadminVendor />}/>
                    <Route path ='Superadminpay' element={<Superadminpay />}/>
                    <Route path ='SuperadminUpdate' element={<SuperadminUpdate />}/>

                    
                   {/* Temporay Routes for future reference */}
                   <Route path='/TermsOfUse' element={<TermsOfUse />} />
                   <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
                   
                    <Route path='/SimManagement' element={<SimManagement />} />
                    <Route path='/TopUps' element={<TopUps />} />  
                    <Route path='/Buydatad' element={<Buydatad />} />  

                </Routes>

            </Layout>
        </BrowserRouter>
    )
}

export default App
